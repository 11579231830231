import Cookies from 'universal-cookie';
import { domain } from '../config';

const cookies = new Cookies();
class CookeService {

    //get cookie
     get(key){
        return cookies.get(key);
    }

    //set cookie
    set(key, value, option){
        cookies.set(key, value, option);
    }

    //remove cookie
    remove(key){
        cookies.remove(key, {path: "/", domain: process.env.NODE_ENV === 'development'?'localhost':domain.substring(3)});
    }
}

export default new CookeService();