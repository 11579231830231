import React from 'react';
import NumberFormat from "react-number-format";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  TextField,
} from '@material-ui/core';

function CreatableAutocomplete(props) {
    const filter = createFilterOptions();
    let { formik, option, name, setter} = props;
    return (
        <Autocomplete
            fullWidth 
            size="small"
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id={name}
            options={option}
            renderOption={(option) => option.lable}
            freeSolo
            value={formik.values[name]}
            onChange={(event, newValue) => {
                // console.log(newValue)
                if (typeof newValue === 'string') {
                formik.setFieldValue(name,{id:'0', lable:newValue});
                } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
                formik.setFieldValue(name,{id:newValue.id, lable:newValue.inputValue});
                    if(setter != undefined && setter != null){
                        let arr = [...option];
                        arr.push({id:newValue.id, lable:newValue.inputValue});
                        setter(arr);
                    }
                } else {
                    formik.setFieldValue(name,newValue==null?'':newValue);
                }
            }}

            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                filtered.push({
                    id:0,
                    inputValue: params.inputValue,
                    lable: `Add "${params.inputValue}"`,
                });
                }
                return filtered;
            }}

            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                return option.inputValue;
                }
                // Regular option
                return option.lable;
            }}
            renderInput={(params) => (
                <TextField {...params} helperText={formik.touched[name] ||  Boolean(formik.errors[name])? formik.errors[name] : ""} 
                error={Boolean(formik.errors[name])} variant="outlined" />
            )}
            />
    );
}
export default CreatableAutocomplete;
