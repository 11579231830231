import React, { Component } from 'react';
import {
    Button,
    TextField,
    InputAdornment
  } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import apiRequest from '../../../services/api';
import CookeService from '../../../services/CookeService';
import { WarningMsg, SuccessMsg, ErrorMsg } from '../../../helpers/Msgbox';

const validationSchema = Yup.object({
    usna: Yup.string().test('checkDuplUsername', 'Already exists', 
        async function (value) {
          const data = {
            value:value,
            usid:CookeService.get('grantitm'),
            clmn:'name'
          }
          const rspd =await apiRequest.validationRequest('/validation_request', data)
          if(rspd){
            return false;
          }else{  
            return true;
          }
        }).required('Username is required'),
    alno: Yup.number().test('checkDuplAlertNo', 'Already exists', 
    async function (value) {
      const data = {
        value:value,
        usid:CookeService.get('grantitm'),
        clmn:'ano'
      }
      const rspd =await apiRequest.validationRequest('/validation_request', data)
      if(rspd){
        return false;
      }else{  
        return true;
      }
    }).required('Alert No is required').positive('Invalid Value Enterd'),
    emal: Yup.string().test('checkDuplEmail', 'Already exists', 
    async function (value) {
      const data = {
        value:value,
        usid:CookeService.get('grantitm'),
        clmn:'email'
      }
      const rspd =await apiRequest.validationRequest('/validation_request', data)
      if(rspd){
        return false;
      }else{  
        return true;
      }
    }).required('Email is required'),
});

class formBlock extends Component {

    constructor(props){
      super(props);
      this.state = {
        username:this.props.name,
        alertNo:this.props.alertNo,
        email:this.props.email,
        usid: this.props.usid
      };
      this.renderUsername = this.renderUsername.bind(this);
      this.renderEmail = this.renderEmail.bind(this);
      this.renderAlertno = this.renderAlertno.bind(this);
  
    }

    renderUsername({field, form}){
        return (
          <TextField
          fullWidth
          variant="outlined"
          id="textfield-usna"
          label="User Name"
          name="usna"
          helperText={form.touched.usna ? form.errors.usna : ''}
          error={Boolean(form.errors.usna)}
          value={this.state.username}
          onChange={(option) => {
            form.setFieldValue(field.name, option.target.value)
            this.setState({username:option.target.value})
          }
        }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountBoxIcon/>
              </InputAdornment>
            )
          }}
        />
        )
      }

      renderEmail({field, form}){
        return (
          <TextField
          fullWidth
          variant="outlined"
          id="textfield-emal"
          label="Email"
          name="emal"
          helperText={form.touched.emal ? form.errors.emal : ''}
          error={Boolean(form.errors.emal)}
          value={this.state.email}
          onChange={(option) => {
            form.setFieldValue(field.name, option.target.value)
            this.setState({email:option.target.value})
          }
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon/>
              </InputAdornment>
            )
          }}
        />
        )
      }

      renderAlertno({field, form}){
        return (
          <TextField
          fullWidth
          variant="outlined"
          id="textfield-alno"
          label="Alert No"
          name="alno"
          helperText={form.touched.alno ? form.errors.alno : ''}
          error={Boolean(form.errors.alno)}
          value={this.state.alertNo}
          onChange={(option) => {
            form.setFieldValue(field.name, option.target.value)
            this.setState({alertNo:option.target.value})
          }
        }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneAndroidIcon/>
              </InputAdornment>
            )
          }}
        />
        )
      }

    render(){
        return(
            <Formik
            //render={(props) => <Form {...props} />}
            initialValues={{
              usna:this.state.username,
              alno:this.state.alertNo,
              emal:this.state.email,
              usid:this.state.usid
            }}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              let files = new FormData();
              files.append('usna', values.usna);
              files.append('alno', values.alno);
              files.append('emal', values.emal);
              files.append('usid', values.usid);
              files.append('grantitm', CookeService.get('grantitm'));
              setTimeout(() => {
                const data = apiRequest.commenRequest('/save_profiledata', files);
                if(data){
                  SuccessMsg('Profile Updated Successfully.')
                }else{
                  ErrorMsg('Profile Updating Unsuccessfull.')
                }
                resetForm();
                setSubmitting(false);
              }, 500);
            }}
          >
          { ({ errors, touched, handleSubmit, isValid, handleChange, handleBlur}) => ( 
            <Form onSubmit={handleSubmit}>
            <div className="py-4">
                <div className="mb-4">
                <Field name="usna"
                  component={this.renderUsername} 
                  />
                </div>
                <div className="mb-3">
                <Field name="alno"
                  component={this.renderAlertno} 
                  />
                </div>
                <div className="mb-3">
                <Field name="emal"
                  component={this.renderEmail} 
                  />
                </div>
                <div className="text-right py-4">
                  <Button type="submit" size="small" className="btn-success m-2">
                    Update
                  </Button>
                </div>
            </div>
            </Form>
            )}
        </Formik>
        )
    }
}
export default formBlock;
