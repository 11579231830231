import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

  const Star = () => {
    return (
      <sup>
        <FontAwesomeIcon icon="star-of-life" color="red" className="opacity-6 ml-1" style={{ fontSize:7}}/> 
      </sup>
    );
  };

  export default Star;