import React, {useState} from 'react';
import {
  Button,
  Menu,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SubMenu(props) {
  const icon = props.icon;
  const buttonclass = props.buttonclass;
  const fontawesomeclass = props.fontawesomeclass;
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleClickMenu = event => {setAnchorElMenu(event.currentTarget);};
  const handleCloseMenu = () => {setAnchorElMenu(null);};
  return (
    <>
    <Button size="small" className={buttonclass ==undefined?"btn-link d-30 p-0 btn-icon hover-scale-sm":buttonclass} onClick={handleClickMenu}>
      <FontAwesomeIcon icon={icon} className={fontawesomeclass == undefined?"font-size-lg":fontawesomeclass} />
    </Button>
    <Menu
      key={props.key}
      icon = {icon}
      keepMounted
      anchorEl={anchorElMenu}
      open={Boolean(anchorElMenu)}
      onClose={handleCloseMenu} 
      classes={{ list: 'p-0' }}
      getContentAnchorEl={null}
      anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      >
      <div className="dropdown-menu p-0">
        {props.children}
      </div>
    </Menu>
    </>
  )
}
export default SubMenu;
