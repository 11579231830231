import React from 'react';
import { PageTitle } from 'layout-components';

import Home from '../../components/Home';

export default function DashboardAnalytics() {
  return (
    <>

          <Home/>
   
    </>
  );
}
