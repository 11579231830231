import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import {ProtectedRoute} from './services/ProtectedRoute';
import { AnimatePresence, motion } from 'framer-motion';
import { BeatLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Example Pages
import Home from './pages/Home';
import Profile from './pages/profile';

const Login = lazy(() => import('./pages/Login'));
const PageError404 = lazy(() => import('./example-pages/PageError404'));
const PageError500 = lazy(() => import('./example-pages/PageError500'));
const PageError505 = lazy(() => import('./example-pages/PageError505'));

const Ticket = lazy(()=>import('./pages/Ticket/Home'));
const Form = lazy(()=>import('./pages/Ticket/New'));
const FormReply = lazy(()=>import('./pages/Ticket/Reply'));
const PreviewTicket = lazy(()=>import('./pages/Ticket/Preview'))
const FinishTicket = lazy(()=>import('./pages/Ticket/Finish'))
const CloseTicket = lazy(()=>import('./pages/Ticket/Close'))
const CreateTask = lazy (()=>import('./pages/Ticket/CreateTask'));
const TerminateTicket = lazy(()=>import('./pages/Ticket/Terminate'))
const NewTask = lazy (()=>import('./pages/ProjectMaster/New'));
const PlaneTask = lazy (()=>import('./pages/ProjectMaster/Plane'));
const TaskHome = lazy (()=>import('./pages/ProjectMaster/Home'));
const PreviewTask = lazy (()=>import('./pages/ProjectMaster/Preview'));
const Tasks = lazy (()=>import('./pages/ProjectMaster/TaskHome'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <BeatLoader color={'#3c44b1'} loading={true} />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <ProtectedRoute
              exact
              path={[
                '/',
                '/home',
                '/ticket_home',
                '/ticket_new',
                '/ticketcreate_task',
                '/profile',
                '/ticket_reply',
                '/ticket_privew',
                '/finish_ticket',
                '/close_ticket',
                '/terminate_ticket',
                '/task_new',
                '/task_plane',
                '/task_create',
                '/task_home',
                '/task_preview',
                '/tasks_home_page'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <ProtectedRoute
                      exact
                      path="/home"
                      component={Home}
                    />
                    <ProtectedRoute
                      exact
                      path="/"
                      component={Home}
                    />
                   <ProtectedRoute exact path ="/ticket_home" component={Ticket}/>
                    <ProtectedRoute exact path ="/ticket_new" component = {Form}/>
                    <ProtectedRoute exact path="/profile" component={Profile} />
                    <ProtectedRoute exact path ="/ticketcreate_task" component={CreateTask}/>
                    <ProtectedRoute exact path="/ticket_reply" component={FormReply}/>
                    <ProtectedRoute exact path="/ticket_privew" component={PreviewTicket}/>
                    <ProtectedRoute exact path="/finish_ticket" component={FinishTicket}/>
                    <ProtectedRoute exact path="/close_ticket" component={CloseTicket}/>
                    <ProtectedRoute exact path="/terminate_ticket" component={TerminateTicket}/>
                    <ProtectedRoute exact path= "/task_new" component={NewTask} />
                    <ProtectedRoute exact path= "/task_plane" component={PlaneTask} />
                    <ProtectedRoute exact path= "/task_create" component={CreateTask} />
                    <ProtectedRoute exact path= "/task_home" component={TaskHome} />
                    <ProtectedRoute exact path= "/task_preview" component={PreviewTask} />
                    <ProtectedRoute exact path= "/tasks_home_page" component={Tasks} />

                  </motion.div>
                </Switch>
              </LeftSidebar>
            </ProtectedRoute>

            <Route
              path={[
                '/PageError404',
                '/PageError500',
                '/PageError505',
                '/login'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={Login} />
                    <Route path="/PageError404" component={PageError404} />
                    <Route path="/PageError500" component={PageError500} />
                    <Route path="/PageError505" component={PageError505} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            {/* this route without body content */}
            {/* <Route
              path={[
                '/usermaster',
              ]}>
              <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
 
                  </motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route> */}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
