import React, { useEffect, useState } from 'react';
import { 
  Card, 
  Table, 
  Button,  
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,    
  TextField,
  InputAdornment 
 } from '@material-ui/core';
import { assetUrl, baseUrl } from '../../../config';
import { useDropzone } from 'react-dropzone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import apiRequest from '../../../services/api';
import CookeService from '../../../services/CookeService';
import { WarningMsg, SuccessMsg, ErrorMsg } from '../../../helpers/Msgbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LoopIcon from '@material-ui/icons/Loop';
import '../../../assets/custom/custom.css';
import auth from '../../../services/auth';


const UserBlock = (props) => {
  const [files, setFiles] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const {isDragActive,isDragAccept,isDragReject,open,getRootProps,getInputProps} = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
     onDrop: async(acceptedFiles) => {
     const url = acceptedFiles.map((file) =>
        URL.createObjectURL(file)
      )

      let file = await fetch(url[0]).then(r => r.blob());
      let formData = new FormData();
      formData.append('image', file);
      formData.append('usid', CookeService.get('grantitm'));
      const data = await apiRequest.commenRequest('/update_image', formData);
      if(data){
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
        SuccessMsg('Profile Image Updated Successfully.');
      }else{
        ErrorMsg('Profile Image Updating Unsuccessfull.')
      }
    }
  });

  const validationSchema = Yup.object({
    cups: Yup.string()
    .required('Current Password is required')
    .test('checkCurrentPass', 'Invalid Password Enterd.', 
        async function (value) {
          console.log(value);
          const data = {
            value:value != undefined?value:'',
            usid:CookeService.get('grantitm'),
            clmn:'password'
          }
          const rspd =await apiRequest.validationRequest('/validation_password', data)
          return rspd
      }),
    nwps: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    pwag: Yup.string()
    .required('Please Confirm your password')
    .oneOf([Yup.ref('nwps')], 'Password does not match'),
});

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const renderCurrentPass = ({field, form}) =>{
    return (
      <TextField
      fullWidth
      variant="outlined"
      id="textfield-cups"
      label="Current Password"
      type="password"
      name="cups"
      helperText={form.touched.cups ? form.errors.cups : ''}
      error={Boolean(form.errors.cups)}
      value={form.values.cups}
      onChange={(option) => {
        form.setFieldValue(field.name, option.target.value)
      }
    }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOpenIcon/>
          </InputAdornment>
        )
      }}
    />
    )
  }

  const renderNewPass = ({field, form}) =>{
    return (
      <TextField
      fullWidth
      variant="outlined"
      id="textfield-nwps"
      label="New Password"
      type="password"
      name="nwps"
      helperText={form.touched.nwps ? form.errors.nwps : ''}
      error={Boolean(form.errors.nwps)}
      value={form.values.nwps}
      onChange={(option) => {
        form.setFieldValue(field.name, option.target.value)
      }
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <VpnKeyIcon/>
          </InputAdornment>
        )
      }}
    />
    )
  }
  
  const renderPassAgain = ({field, form}) =>{
    return (
      <TextField
      fullWidth
      variant="outlined"
      id="textfield-pwag"
      label="Confirm Password"
      type="password"
      name="pwag"
      helperText={form.touched.pwag ? form.errors.pwag : ''}
      error={Boolean(form.errors.pwag)}
      value={form.values.pwag}
      onChange={(option) => {
        form.setFieldValue(field.name, option.target.value)
      }
    }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LoopIcon/>
          </InputAdornment>
        )
      }}
    />
    )
  }

  const thumbs = files.map((file) => (
    <div
      key={file.name}
      className="rounded-circle avatar-image overflow-hidden d-100 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
      <img
        className="img-fluid img-fit-container rounded-sm"
        src={file.preview}
        alt="..."
      />
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
    return (
        <Card className="card-box text-center p-4" style={{boxShadow:'none'}}>
          <div className="py-4 d-flex align-items-center justify-content-center">
            <div className="dropzone rounded-circle shadow-sm">
              <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper d-100 rounded-circle dropzone-avatar">
                  <div className="avatar-icon-wrapper d-100 rounded-circle m-2">
                    <Button
                      onClick={open}
                      className="avatar-button badge shadow-xxl btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-30 badge-circle btn-success hover-scale-lg text-white">
                      <EditTwoToneIcon className="w-50" />
                    </Button>

                    <div>
                      {isDragAccept && (
                        <div className="rounded-circle overflow-hidden d-100 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <CheckIcon className="d-30" />
                        </div>
                      )}
                      {isDragReject && (
                        <div className="rounded-circle overflow-hidden d-100 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <CloseTwoToneIcon className="d-40" />
                        </div>
                      )}
                      {!isDragActive && (
                        <div className="rounded-circle overflow-hidden d-100 bg-second text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center">
                          <AccountCircleTwoToneIcon className="d-30" />
                        </div>
                      )}
                    </div>
                    {thumbs.length == 0 ?
                    <div
                      className="rounded-circle avatar-image overflow-hidden d-100 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                      <img
                        className="img-fluid img-fit-container rounded-sm"
                        src={assetUrl+props.image}
                        alt="..."
                      />
                    </div>:<div>{thumbs}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        <h4 className="font-size-lg font-weight-bold my-2">
        {props.name}
        </h4>
        <Table className="table table-striped text-left mb-0" style={{marginTop:'15px', fontSize:'12px'}}>
            <tbody>
                <tr>
                    <th>
                        User ID
                    </th>
                    <td className="text-right">{props.code}</td>
                </tr>
                <tr>
                    <th>
                        Multi Brach Login
                    </th>
                    <td className="text-right">{props.multi}</td>
                </tr>
                <tr>
                    <th>
                        Login Expire On
                    </th>
                    <td className="text-right">{props.expire}</td>
                </tr>
            </tbody>
        </Table>
        <div className="d-flex justify-content-between text-center line-height-sm" style={{marginTop:'15px'}}>
        <Button variant="contained"onClick={handleClickOpen3('paper')} size="small" className="btn-pill m-2 btn-first text-center" style={{width:'100%'}}>
          Change Password 
        </Button>
        </div>   
        <Dialog
          open={open3}
          onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          >
        <Formik
            initialValues={{
              cups:'',
              nwps:'',
              pwag:''
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              setOpen3(false);
              let files = new FormData();
              files.append('nwps', values.nwps);
              files.append('grantitm', CookeService.get('grantitm'));
              setTimeout(() => {
                const data = apiRequest.commenRequest('/save_nawpassword', files);
                if(data){
                  SuccessMsg('Password Updated Successfully.')
                  auth.logout();
                  setTimeout(
                    () => window.location.reload(false),
                    3000
                  );
                }else{
                  ErrorMsg('Password Updating Unsuccessfull.')
                }
                resetForm();
                setSubmitting(false);
              }, 500);
            }}
          >
        { ({ errors, touched, handleSubmit, isValid, handleChange, handleBlur}) => ( 
        <Form onSubmit={handleSubmit}> 
          <DialogTitle id="scroll-dialog-title">Change Password</DialogTitle>
          <DialogContent dividers={scroll === 'paper'} >
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              open={open3}
              >
            <div className="py-4">
                <div className="mb-4">
                <Field name="cups"
                  component={renderCurrentPass} 
                  />
                </div>
                <div className="mb-3">
                <Field name="nwps"
                  component={renderNewPass} 
                  />
                </div>
                <div className="mb-3">
                <Field name="pwag"
                  component={renderPassAgain} 
                  />
                </div>
            </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-secondary p-4">
            <Button
              onClick={handleClose3}
              size="small"
              className="btn-outline-dark mr-3">
              Cancel
            </Button>
            <Button type="submit" className="btn-success">
              Save
            </Button>
          </DialogActions>
          </Form>
            )}
        </Formik>
        </Dialog>
      </Card>
    )
}
export default UserBlock;
