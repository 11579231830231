import React from 'react';

import {
    Grid,
    Card,
    Table
  } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotificationPanel = (props) => {

    const { type, action, label, createdBy, createdDate, updatedBy,  updatedDate, terminatedBy, terminatedDate ,finishedBy,finishedDate } = props;
    return (
         <Grid container spacing={0} style={{marginTop:'15px'}}>
         <Grid item lg={8}>  
         </Grid>
         <Grid item lg={4}>
               <Card className="card-box p-4">
               <div className="d-flex align-items-center justify-content-between py-2">
               <div className="d-40 rounded-circle border-0 my-2 card-icon-wrapper bg-second text-white btn-icon text-center">
                   <FontAwesomeIcon
                   icon={['far', 'bell']}
                   className="font-size-lg"
                   />
               </div>
               <div className="d-flex align-items-center">
                   <div className="m-2">
                    {action}
                   </div>
               </div>
               </div>
               <Table className="table table-striped" style={{fontSize:'0.8rem'}}>
                   <tbody>
                        <tr style={{display:type==1?'':'none'}}>
                           <th>Status</th>     
                           <td className="text-right">{label}</td>
                        </tr>   
                       <tr>
                           <th>Created By</th>     
                           <td className="text-right">{createdBy}</td>
                       </tr>  
                       <tr>
                           <th>Created Date & Time</th>     
                           <td className="text-right">{createdDate}</td>
                       </tr>
                       <tr style={{display:(updatedBy != undefined && updatedBy != '')?'':'none'}}>
                           <th>Last Updated By</th>     
                           <td className="text-right">{updatedBy}</td>
                       </tr>  
                       <tr style={{display:(updatedDate != undefined && updatedDate != '')?'':'none'}}>
                           <th>Last Updated Date & Time</th>     
                           <td className="text-right">{updatedDate}</td>
                       </tr>
                       <tr style={{display:(finishedBy != undefined && finishedBy != '')?'':'none'}}>
                           <th>Finished By</th>     
                           <td className="text-right">{finishedBy}</td>
                       </tr>  
                       <tr style={{display:(finishedBy != undefined && finishedBy != '')?'':'none'}}>
                           <th>Finished Date & Time</th>     
                           <td className="text-right">{finishedDate}</td>
                       </tr>
                       <tr style={{display:(terminatedBy != undefined && terminatedBy != '')?'':'none'}}>
                           <th>Terminated By</th>     
                           <td className="text-right">{terminatedBy}</td>
                       </tr>  
                       <tr style={{display:(terminatedBy != undefined && terminatedBy != '')?'':'none'}}>
                           <th>Terminated Date & Time</th>     
                           <td className="text-right">{terminatedDate}</td>
                       </tr>         
                   </tbody>
               </Table>
           </Card>
           </Grid>
        </Grid>
    )
}

export default NotificationPanel;