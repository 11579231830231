import React from 'react';
import BlockUi from 'react-block-ui';
import {FadeLoader} from 'react-spinners';

function Loader(props) {
  const { loading } = props;

  return (
    <BlockUi
    className="p-0"
    tag="div"
    blocking={loading}
    loader={
      <FadeLoader color={'var(--first)'} loading={loading} />
    }>
      {props.children}
    </BlockUi>
  )
}
export default Loader;
