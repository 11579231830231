import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
  } from '@material-ui/core';
import UserBlock from '../../components/profile/User-block'
import FormBlock from '../../components/profile/form-block'
import InfoBlock from '../../components/profile/info-block'
import apiRequest from '../../services/api';
import BlockUi from 'react-block-ui';
import {FadeLoader} from 'react-spinners';

const Profile = (props) => {
  const [image, setImage] = useState('/disk/blank.jpg');
  const [name, setName] = useState('');
  const [userCode, setUserCode] = useState('');
  const [multiBr, setMultiBr] = useState('');
  const [expire, setExpire] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertNo, setalertNo] = useState('');
  const [email, setEmail] = useState('');
  const [registrdt, setRegistrdt] = useState('');
  const [lastlogin, setLastlogin] = useState('');
  const [lastloginfrm, setLastloginfrm] = useState('Ruwanwella');
  const [logintype, setLoginType] = useState('');
  const [profilectby, setProfilectby] = useState('');
  const [profilecton, setProfilecton] = useState('');
  const [lastupdtby, setLastupdtby] = useState('');
  const [lastupdton, setLastupdton] = useState('');
  const [userid, setUserid] = useState('');

  useEffect(() => {
    setLoading(true);
    const userData = async() => {
      const usdt = await apiRequest.loadUser(2);
      setImage(usdt.imge);
      setUserCode(usdt.data.user_id);
      setMultiBr(usdt.data.mlm == 1?'Access':'Band');
      setExpire(usdt.data.pwe);
      setName(usdt.data.name);
      setalertNo(usdt.data.ano);
      setEmail(usdt.data.email);
      setRegistrdt(usdt.data.rgd);
      setLastlogin(usdt.data.lld);
      setLoginType(usdt.data.umo==1?'Admin':'User');
      setProfilectby(usdt.data.cna);
      setProfilecton(usdt.data.ctd);
      setLastupdtby(usdt.data.lna);
      setLastupdton(usdt.data.utd);
      setUserid(usdt.data.id)
      setLoading(usdt.data.user_id !== undefined?false:true)
    }
    userData();
  }, [])

  return (
    <>
    <BlockUi
        className="p-3 block-loading-overlay-light"
        tag="div"
        blocking={loading}
        loader={
          <FadeLoader  loading={loading} />
    }>
     <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
        <Card className="p-4 mb-4">
            <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
                <UserBlock 
                  image={image}
                  code={userCode}
                  multi={multiBr}
                  expire={expire}
                  name={name}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
            {!loading?
                <FormBlock
                   name={name}
                   alertNo={alertNo}
                   email={email}
                   usid={userid}
                />
              :''}
            </Grid>
            <Grid item xs={12} lg={4}>
               <InfoBlock 
                redt={registrdt}
                lslg={lastlogin}
                lgfm={lastloginfrm}
                lgty={logintype}
                pfct={profilectby}
                pfco={profilecton}
                lsup={lastupdtby}
                lsuo={lastupdton}
               />
            </Grid>
            </Grid>
        </Card>
        </Grid>
     </Grid>
     </BlockUi>
    </>
  );
}

export default Profile;
