import React from 'react';
import Swal from 'sweetalert2/src/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'

/*
  msgbox-zindex class defined in base.scss
*/
export function SuccessMsg(msg){
  return Swal.fire({
    icon:'success',
    title:'Success!',
    text:msg,
    customClass: {
      container: 'msgbox-zindex',
      title: 'text-success',
    }
  })
}
export function WarningMsg(msg){
  return Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: msg,
      customClass: {
        container: 'msgbox-zindex',
        title: 'text-warning',
      }
    })
}
export function ErrorMsg(msg){
  return Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: msg,
      customClass: {
        container: 'msgbox-zindex',
        icon: 'text-danger',
        title: 'text-danger',
      }
    })
}
export function ConfirmMsg(msg){
  return Swal.fire({
    target: document.getElementById('form-modal'),
      icon  : 'info',
      title : 'Confirm',
      text  : msg,
      showCancelButton: true,
      focusConfirm    : true,
      confirmButtonText     :'Yes',
      cancelButtonText      :'No',
      customClass: {
        container: 'msgbox-zindex',
        icon: 'text-info',
        title: 'text-info',
      }
  })
}
export function SuccessMsgTopUp(msg){
  return Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Success!',
      text:msg,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        container: 'msgbox-zindex',
        title: 'text-success',
      }
  })
}
export function ImageMsg(title,msg,imageUrl){
  return Swal.fire({
    title: title,
    text:msg,
    imageUrl: imageUrl,
    imageWidth: 400,
    imageHeight: 400,
    imageAlt: title,
    customClass: {
      container: 'msgbox-zindex',
      //title: 'text-success',
    }
  })
}
