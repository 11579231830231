import CookeService from './CookeService';
import apiRequest from './api';

let authenticated = false;

const LogoutFun = async () =>{

  //const result = await apiRequest.getRequest('/logout');
  CookeService.remove('access_token');
  CookeService.remove('userprmt');
  CookeService.remove('grantitm');
  authenticated = false;
  window.location.href = "/login";
}

class Auth{

    //load in
    login = () => {
        authenticated = true;
    }

    //logout
    logout = () => {
        LogoutFun();
    }

    isAuthenticated(){
        let token = CookeService.get('access_token');
        if(token !== undefined){
            authenticated = true;
            return true;
        }else{
            authenticated = false;
            return false;
        }
    }
}

export default new Auth();