import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { setHeaderDrawerToggle } from '../../reducers/ThemeOptions';
import auth from '../../services/auth';

const HeaderDrawer = (props) => {
  const { headerDrawerToggle, setHeaderDrawerToggle } = props;

  const logOut = () => {
    setHeaderDrawerToggle(!headerDrawerToggle);
    auth.logout();
  };

  return (
    <>
      <div className="app-drawer-wrapper">
        <Button
          size="small"
          onClick={logOut}
          className={clsx(
            'btn-transition-none navbar-toggler bg-transparent p-0 hamburger hamburger--elastic',
            { 'is-active': headerDrawerToggle }
          )}
          disableRipple>
            <FontAwesomeIcon
              icon={['fa', 'power-off']}
              style={{fontSize:'20px'}}/>
        </Button>
      </div>
      <div
        onClick={logOut}
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDrawer);
