import React from 'react';
import { Table } from '@material-ui/core';

const infoBlok = (props) => {
    return (
        <Table className="table table-striped text-left mb-0" style={{fontSize:'12px'}}>
            <tbody>
                <tr>
                    <th>Register Date</th>
                    <td>{props.redt}</td>
                </tr>
                <tr>
                    <th>Last Login On</th>
                    <td>{props.lslg}</td>
                </tr>
                <tr>
                    <th>Last Login From</th>
                    <td>{props.lgfm}</td>
                </tr>
                <tr>
                    <th>Login Type</th>
                    <td>{props.lgty}</td>
                </tr>
                <tr>
                    <th>Profile Created By</th>
                    <td>{props.pfct}</td>
                </tr>
                <tr>
                    <th>Profile Created On</th>
                    <td>{props.pfco}</td>
                </tr>
                <tr>
                    <th>Last Updating By</th>
                    <td>{props.lsup}</td>
                </tr>
                <tr>
                    <th>Last Updating On</th>
                    <td>{props.lsuo}</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default infoBlok;