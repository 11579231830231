import React, { useState, useStyles } from 'react';

import clsx from 'clsx';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Button } from '@material-ui/core';

import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Redirect } from 'react-router-dom';
import { Height } from '@material-ui/icons';

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    titleIcon,
    children,
    formik
  } = props;
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [closeUrl, setcloseUrl] = useState(props.closeUrl == undefined?null:props.closeUrl);
  const [sccButton, setSccButton] = useState(props.SCCButton?props.SCCButton:[]);
  const [fonAwesome, setFontAwesome] =  useState(props.fonAwesome==undefined?null:props.fonAwesome);
  const [buttonName, setButtonName] = useState(props.buttonName==undefined?null:props.buttonName);
  const [buttonStyle , setButtonColor] = useState(props.buttonStyle==undefined?null:props.buttonStyle);
  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  function closeFunction(url){
    if(url == null){
      window.location.reload()
    }else{
      props.history.push(url)
    }
  }

  function renderButtons(sccButton){
   return ( <div className="d-flex align-items-center justify-content-right flex-wrap">
      {sccButton.indexOf(4) > -1? 
        <Button variant="contained" onClick={props.clickFunction} className={buttonStyle} style={{ width:'100px'}}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={fonAwesome}/>
          </span>
          <span className="btn-wrapper--label">{buttonName}</span>
        </Button>:<></>
        }
      {sccButton.indexOf(1) > -1?
             <Button type="submit" variant="contained" disabled={(formik != undefined && formik.isSubmitting > 0 && formik.isValidating)?true:false} className="btn-success m-2" style={{width:'100px'}}>
             <span className="btn-wrapper--icon">
               <FontAwesomeIcon icon={['far', 'check-circle']}/>
             </span>
             <span className="btn-wrapper--label">Save</span>
           </Button>
           :
           <></>
      }
      {sccButton.indexOf(2) > -1?    
          <Button variant="contained" onClick={(e) => window.location.reload()} className="btn-first m-2" style={{width:'100px'}}>
             <span className="btn-wrapper--icon">
               <FontAwesomeIcon icon={['far', 'clock']}/>
             </span>
             <span className="btn-wrapper--label">Clear</span>
           </Button>:<></>}
      {sccButton.indexOf(3) > -1? 
            <Button variant="contained" onClick={(e) => closeFunction(closeUrl)} className="btn-danger m-2" style={{width:'100px'}}>
             <span className="btn-wrapper--icon">
               <FontAwesomeIcon icon={['far', 'times-circle']}/>
             </span>
             <span className="btn-wrapper--label">Close</span>
           </Button>:<></>}
    </div>
   )
  }

  const TitleIcon = props.titleIcon?props.titleIcon:HomeWorkTwoToneIcon;
  return (
    <>
      <div
        className={clsx('app-page-title rounded', pageTitleStyle, pageTitleBackground, {
          'app-page-title--shadow': pageTitleShadow
        })}
        style={{marginTop:'0px', marginLeft:'3px', marginRight:'3px', marginBottom:'5px', padding:'0px'}}
        >
        <div>
          <div className="app-page-title--first">
              <div className="app-page-title--iconbox d-40" style={{marginLeft:'10px'}}>
                <div className="d-40 d-flex align-items-center justify-content-center display-1" >
                  <TitleIcon className="text-primary"/>
                </div>
              </div>
            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
        
        {sccButton.length == 0 ?
        <div className="d-flex align-items-center justify-content-right flex-wrap">
            <Button 
            title="Create New" 
            className="btn-dark btn-icon btn-animated-icon btn-transition-none btn-pill d-40 p-0 m-2"
            onClick={(e) =>props.history.push(props.redirectUrl)}
            >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={['far', 'file']}
                className="font-size-xs"
              />
            </span>
          </Button>
          {
            props.secoundryBtn == true ?
            <Button 
              title={props.secoundryBtnTitle}
              className="btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-40 p-0 m-2"
                onClick={(e) =>props.history.push(props.secoundryBtnUrl)}
              >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon
                  icon={['far', 'file']}
                  className="font-size-xs"
                />
              </span>
            </Button>:''
          }
        </div>
        :
        renderButtons(sccButton)
        }

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default withRouter(connect(mapStateToProps)(PageTitle));
