import {baseUrl, dbUrl} from '../config';
import axios from 'axios';
import CookeService from './CookeService';

export default{

    //get db name
    async loadDbname(data){
        const request = axios({ url:dbUrl+'/db_config',method:'POST',data:data })
        const returnData = request.then((response) => response.data);
        return returnData;
    },

    //load database credentioals
      async doLogin(data, dbconfig){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
        const request = axios({ url:baseUrl+'/login',method:'POST',data:data})
        const returnData = request.then((response) => response.data);
        return returnData;
      },

      //handle db cookie
      dbCookie(data){
        const options = {path: '/', secure :true, sameSite:true};
        CookeService.set('dbuser', data.data.dun, options);
        CookeService.set('dbpass', data.data.dup, options);
        CookeService.set('dbname', data.data.dbi, options);
        CookeService.set('prusid', data.data.pui, options);
      },

      //access token
      async handlelogincookie(response){
            const options = {path: '/', secure :true, sameSite:true};
            CookeService.set('access_token', response.token, options);
            CookeService.set('grantitm', response.id, options);
            CookeService.set('userprmt', response.prmt, options);
            return true;
      },

      //get user infomation Secoundary
      async loadUser(sts){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
        const usid = CookeService.get('grantitm');
        const data = {
          usid : usid,
          stts : sts
        }
        const request = axios({ url:baseUrl+'/granted_detail',method:'POST', data:data })
        const returnData = request.then((response) => response.data);
        return returnData;
      },

      //commen request (tesing)
      async commenRequest(url, data){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
        const request = axios({ url:baseUrl+url,method:'POST', data:data })
        const returnData = request.then((response) => response.data);
        return returnData;
      },

      //validate user
      async validationRequest(url, data){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
        const request = axios({ url:baseUrl+url,method:'POST', data:data })
        const returnData =await request.then((response) => response.data);
        return returnData;
      },

      //url and responce
      async requestWithUrl(url){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
        const request = axios({ url:dbUrl+url,method:'POST'})
        const returnData =await request.then((response) => response.data);
        return returnData;
      },

      //load home data
      async loadCommenHomedata(url,data){
        axios.defaults.headers = {
          'Authorization': `Bearer ${CookeService.get('access_token')}`,
        }
     
        const request = axios({ url:baseUrl+url,method:'POST', data:data})
        const returnData =await request.then((response) => response.data);
        return returnData;
      },

      //load permissions
      async loadPermissions(url){
          axios.defaults.headers = {
            'Authorization': `Bearer ${CookeService.get('access_token')}`,
          }
          const request = axios({ url:baseUrl+url,method:'POST'})
          const returnData =await request.then((response) => response.data);
          return returnData;
        },


}